/*
  TODO:
    This logic and the logic for the blowout discounts should be moved to the backend.
      1. Create a new endpoint like 'getLocationWithCustomerPrices'
      2. Merge both requests 'getLocation' and 'getColorBarUnlimitedMemberData' toguether
      3. Calculate the prices and discounts for all the services for the current customer on that endpoint and return those new prices and discounts to the frontend
      4. Return only the necessary data to the front end (the location object is huge and we're using almost nothing for the booking flow)
      5. Remove this and simplify the logic on the hairColorBarBooking store.
*/
export function addUnlimitedMembershipDiscounts(inputService, memberData = {}) {
  const service = { ...inputService };
  const {
    serviceDiscount,
    rootsOnlyPrice,
    freeServices = [],
    urmMemberOrInCart,
  } = memberData;

  let key = 'discountPrice';
  let freeKey = 'isFree';

  if (!urmMemberOrInCart) {
    key = 'forMembersPrice';
    freeKey = 'forMembersFree';
  }

  if (freeServices.includes(service.code)) {
    service[key] = rootsOnlyPrice;
    service[freeKey] = true;
    return service;
  }

  if (/blowout/.test(service.code)) {
    service[key] = service.price - service.price * serviceDiscount;
    return service;
  }

  // roots service
  if (/_roots_/.test(service.code)) {
    service[key] = service.price - rootsOnlyPrice;
    service[key] -= service[key] * serviceDiscount;
    return service;
  }

  service[key] = service.price - service.price * serviceDiscount;
  return service;
}

export function extractDiscounts(promos, selectedShipping) {
  var discounts = [];

  promos.forEach(function(promo) {
    if (promo.asCredits) {
      return;
    }
    let offers = promo.offers || [];
    let offersWithDisplayNames = offers.filter(offer => {
      return !!offer.display_name;
    });

    if (offersWithDisplayNames.length > 1) {
      //list for promos that contain more than one offer
      _displayPromoAsOfferList(promo, discounts, selectedShipping);
    } else {
      //creates a header row where the promo assumes the values of the offer
      _displayPromoAsOffer(promo, discounts, selectedShipping);
    }
  });

  return discounts;

  function _displayPromoAsOffer(promo, discounts, selectedShipping) {
    var firstChildOffer = promo.offers[0];

    if (selectedShipping && selectedShipping.price && firstChildOffer.type === 'freeShipping') {
      return;
    }

    if (!promo.id) {
      //- promos in confirmation page, order history do not have ids
      //- thats all we need to display in confirmation/invoice history page
      discounts.push({
        header: true,
        amt: promo.amount,
        label: promo.displayName,
        showOriginalPrice: true,
      });
      return;
    }

    var amount;
    if (promo.displayAmount) {
      amount = Math.abs(promo.displayAmount) * -1;
    } else if (firstChildOffer.displayAmount) {
      amount = Math.abs(firstChildOffer.displayAmount) * -1;
    } else {
      amount = Math.abs(promo.amount) * -1;
    }

    var discHeader = {
      header: true,
      displayPrice: amount < 0,
      promo_id: promo.id,
      amt: amount,
      label: firstChildOffer.display_name ? firstChildOffer.display_name : promo.displayName,
      can_remove: promo.can_remove,
      showOriginalPrice: firstChildOffer.showOriginalPrice,
      type: promo.type,
      offerType: firstChildOffer.type,
    };

    if (discHeader.header && discHeader.label && promo.type === 'bundle' && discHeader.amt === 0) {
      return;
    }

    discounts.push(discHeader);
  }

  function _displayPromoAsOfferList(promo, discounts, selectedShipping) {
    // Filter out the 2-3 day free shipping if selected shipping is 1-2 day for site wide shipping
    const filteredOffers = promo.offers.filter(function(offer) {
      if (selectedShipping && selectedShipping.price && offer.type === 'freeShipping') {
        return false;
      }

      return true;
    });

    if (!promo.id) {
      //- promos in confirmation page, order history do not have ids
      //- thats all we need to display in confirmation/invoice history page
      discounts.push({
        header: true,
        amt: promo.amount,
        label: promo.displayName,
        showOriginalPrice: true,
      });

      return;
    }

    var discHeader = {
      header: true,
      promo_id: promo.id,
      label: promo.displayName,
      can_remove: promo.can_remove,
      showOriginalPrice: !!promo.showOriginalPrice,
      type: promo.type,
      displayPrice: false,
      amt: promo.amount,
    };

    if (discHeader.header && discHeader.label && promo.type === 'bundle' && discHeader.amt === 0) {
      return;
    }

    var discOffers = [];
    var nullAmts = ['customerPromo', 'freeProduct', 'credit'];
    var someOfferHasDisplay = false;

    filteredOffers.forEach(function(offer) {
      if (+offer.hideProduct) {
        return;
      }

      var displayAmount = +offer.amount;
      if (Object.hasOwnProperty.call(offer, 'displayAmount')) {
        displayAmount = +offer.displayAmount;
      }

      var display = false;
      if (nullAmts.indexOf(offer.type) > -1) {
        displayAmount = null;
        display = true;
      }

      var amt = offer.showOriginalPrice ? Math.abs(displayAmount) * -1 : 0;

      if (amt) {
        display = true;
      }

      if (offer.type != 'freeShipping' && !offer.displayAmount) {
        display = false;
      }

      if (display) {
        someOfferHasDisplay = true;
      }

      discOffers.push({
        display: display,
        header: false,
        label: offer.display_name || promo.displayName,
        type: offer.type,
        offerType: offer.type,
        amt: amt,
        can_remove: false,
        showOriginalPrice: Boolean(offer.showOriginalPrice),
      });
    });

    if (!someOfferHasDisplay) {
      discHeader.displayPrice = true;
    }

    discounts.push(discHeader);

    if (discOffers.length) {
      //flatten the array, works faster than .concat()
      discounts.push.apply(discounts, discOffers);
    }
  }
}

export function checkAppointmentStartTime(startAt, maxHours = 24) {
  const now = new Date();
  const nowUTC = now.toISOString();
  const d1 = new Date(startAt);
  const d2 = new Date(nowUTC);
  const diff = d1 - d2;
  const hours = Math.round((diff / 1000 / 60 / 60) * 100) / 100;
  return ((hours < maxHours) ? true : false);
}

const longHairText = 'I have really long and thick hair.';
const colorWonderText = 'Guest is interested in ColorWonder Demi.';
export function getComments(notes, longHair = false, includesColorWonder = false) {

  if (!notes && !longHair && !includesColorWonder) {
    return null;
  }

  let comments = notes || ''; // ensure notes is a string

  if (comments && comments.slice(-1) !== '.') {
    comments += '.';
  }

  if (longHair) {
    comments += ` ${longHairText}`;
  }

  if (includesColorWonder) {
    comments += ` ${colorWonderText}`;
  }

  return comments.trim();
}

export function getNotesFromComments(comments) {
  let longHair = false;
  let notes = null;

  if (!comments) {
    return { longHair, notes };
  }

  notes = comments.replace(longHairText, '');
  if (notes !== comments) {
    longHair = true;
  }
  return { longHair, notes };
}

export function getDateFromCalDate(calDate) {
  var dArr = calDate.toString().replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, '$1-$2-$3').split('-');
  return new Date(parseInt(dArr[0]), parseInt(dArr[1]) - 1, parseInt(dArr[2]), 0, 0, 0);
}

export async function applyServicePromos(inputService, inputAddon, inputAddOnTreatments, discounts = []) {
  const service = { ...inputService };
  const addon = { ...inputAddon };
  const addOnTreatments = inputAddOnTreatments || [];
  delete service.discountPrice;
  delete addon.discountPrice;
  addOnTreatments.forEach(tr => { delete tr.discountPrice; });

  let discountsToApply = [...discounts];
  let serviceDiscount = 0;
  let addonDiscount = 0;
  let addOnTreatmentsDiscount = 0;
  const discountsApplied = [];

  service.treatmentsIncluded.forEach(tr => {
    const disc = discountsToApply.find(d => d.product.code === tr.product.code);
    if (disc) {
      serviceDiscount += disc.discountObject.amount;
      discountsApplied.push(disc);
    }
  });
  discountsToApply = discountsToApply.filter(d => !discountsApplied.includes(d));

  if (addon.treatmentsIncluded && addon.treatmentsIncluded.length) {
    addon.treatmentsIncluded.forEach(tr => {
      const disc = discountsToApply.find(d => d.product.code === tr.product.code);
      if (disc) {
        addonDiscount += disc.discountObject.amount;
      }
    });
  }

  if (addOnTreatments.length) {
    addOnTreatments.forEach(tr => {
      const disc = discountsToApply.find(d => d.product.code === "PS-AD-BOOST" && tr.code == 'boosts');
      if (disc) {
        addOnTreatmentsDiscount += disc.discountObject.amount;
      }
    });
  }

  if (serviceDiscount > 0) {
    service.discountPrice = service.price - serviceDiscount;
  }
  if (addonDiscount > 0) {
    addon.discountPrice = addon.price - addonDiscount;
  }
  if (addOnTreatmentsDiscount > 0) {
    addOnTreatments.forEach(tr => {
      tr.discountPrice = tr.price - addOnTreatmentsDiscount;
    });
  }

  return { service, addon, addOnTreatments };
}

export function matchItemsWithDiscounts(items, discounts) {
  const discountedItems = [];
  items.forEach(it => {
    const disc = discounts[it.key];
    if (disc) {
      discountedItems.push({
        ...it,
        discountObject: disc
      });
    }
  });
  return discountedItems;
}